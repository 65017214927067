import React, { useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import { graphql, type PageProps } from 'gatsby';
import qs from 'query-string';
import { v4 as uuid } from 'uuid';

import { normalizeString } from '../helpers';
import CollapsibleCard from '../views/CollapsibleCard';
import { StaticImage } from '../views/Image';
import PageLayout from '../views/Layout/Page';
import MetaTags from '../views/MetaTags';
import T, { TNamespace, useTranslation } from '../views/Translate';

import styles from './faq.module.scss';

export const query = graphql`
  query ($language: String!) {
    sections: allStrapiFaqSection(filter: { locale: { eq: $language } }, sort: { fields: section_id, order: ASC }) {
      roles: group(field: role) {
        role: fieldValue
        nodes {
          id
          section_id
          name
          role
          faqs {
            id
          }
        }
      }
    }

    faqs: allStrapiFaq(filter: { locale: { eq: $language } }, sort: { fields: order, order: ASC }) {
      nodes {
        id
        faq_section {
          id
        }
        order
        question
        answer {
          data {
            answer
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;

interface FAQSection {
  id: string;
  section_id?: string;
  name: string;
  role: string;
  faqs: {
    id: string;
  }[];
}

interface FAQItem {
  id: string;
  faq_section?: {
    id: string;
  };
  order: number;
  question: string;
  keywords?: string;
  answer: {
    data: {
      answer: string;
      childMarkdownRemark: {
        html: string;
      };
    };
  };
}

interface FAQRole {
  role: string;
  nodes: FAQSection[];
}

interface FAQSchema {
  sections: {
    roles: FAQRole[];
  };
  faqs: {
    nodes: FAQItem[];
  };
}

interface PageData extends PageProps {
  data: FAQSchema;
}

interface Query {
  search?: string;
  role?: 'driver' | 'owner';
}

export default function FAQPage({ data, location }: PageData) {
  const { t } = useTranslation();
  const roles = useMemo(() => data.sections.roles.map(it => it.role), [data.sections]);
  const query = useMemo(() => {
    let query: Query = {};
    if (location.search) {
      query = qs.parse(location.search) as Query;
    }
    return query;
  }, [location]);
  const [currentRole, setCurrentRole] = useState(() => {
    if (!roles.length) return null;
    let result: string;
    if (query.role) {
      result = roles.find(it => it === query.role);
    }
    return result ?? roles[0];
  });
  const [search, setSearch] = useState(query.search ?? '');

  useEffect(() => {
    setSearch(prevSearch => (prevSearch === query.search ? prevSearch : ''));
  }, [currentRole, query.search]);

  useEffect(() => {
    for (const role of data.sections.roles) {
      for (const section of role.nodes) {
        if (location.href.split('?s=')[1]?.includes(normalizeString(section.name))) {
          setCurrentRole(role.role);
        }
      }
    }

    const scrollToFaq = setInterval(() => {
      const targetId = location.href.substring(location.href.indexOf('#') + 1, location.href.indexOf('?'));
      const element = document.getElementById(targetId);

      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        clearInterval(scrollToFaq);
      }
    }, 1000);
  }, [data.sections.roles, location.href]);

  return (
    <PageLayout pageview="faq">
      <TNamespace name="faq">
        <MetaTags title="faq.title" />
        <main className="main-container container container--top container--bottom">
          <div className={styles.header}>
            <T as="h1" id="faq.interest" />
            <T as="p" id="faq.reason" />
          </div>
          <div className={styles.roles}>
            {roles.map(role => (
              <button
                className={cn(styles.roleButton, role, {
                  selected: role === currentRole,
                })}
                key={role}
                onClick={() => setCurrentRole(role)}
                type="button"
              >
                <StaticImage alt="" name={`faq_${role}`} />
                <T as="span" id={`faq.roles.${role}.button`} />
              </button>
            ))}
          </div>

          <div className={styles.filter}>
            <label className={styles.input}>
              <i className="icon icon-xx icon-search" />
              <input
                onChange={e => setSearch(e.target.value)}
                placeholder={t('faq:faq.search.placeholder')}
                type="text"
                value={search}
              />
              {search.length > 0 && (
                <button
                  aria-label="Reset Search"
                  className={styles.searchResetButton}
                  onClick={() => setSearch('')}
                  type="button"
                >
                  <i className="icon icon-close" />
                </button>
              )}
            </label>
          </div>

          {data.sections.roles.map(roleContent => {
            if (roleContent.role !== currentRole) return null;
            return (
              <FAQRoleContent
                faqs={data.faqs.nodes}
                filter={search}
                key={roleContent.role}
                location={location}
                sections={roleContent}
              />
            );
          })}
        </main>
      </TNamespace>
    </PageLayout>
  );
}

interface RoleContentProps {
  filter: string;
  sections: FAQRole;
  faqs: FAQItem[];
  location: Location;
}

function FAQRoleContent(props: RoleContentProps) {
  const faqs = useMemo(() => {
    const answers = props.faqs.filter(it => props.sections.nodes.some(section => section.id === it.faq_section?.id));
    if (props.filter === '') return answers;
    return answers.filter(it => {
      return (
        (it.keywords ?? '').toLowerCase().includes(props.filter) ||
        it.question.toLowerCase().includes(props.filter) ||
        it.answer.data.answer.toLowerCase().includes(props.filter.toLowerCase())
      );
    });
  }, [props.faqs, props.filter]);
  const sections = useMemo(() => {
    if (props.filter === '') return props.sections.nodes;
    return props.sections.nodes.filter(section => faqs.some(it => it.faq_section?.id === section.id));
  }, [props.sections, faqs]);

  const hasSections = sections.length > 0;
  const isFiltered = props.filter !== '' && hasSections;

  return (
    <div className={styles.content}>
      {!hasSections ? (
        <>
          <T as="h3" id="faq.cant-find-msg" />
          <T as="p" id="faq.suggestion" />
        </>
      ) : (
        <T as="h2" id={`faq.recommended.${props.sections.role}`} />
      )}
      <div className={styles.sections}>
        {(hasSections ? sections : props.sections.nodes).map(section => {
          const questions = faqs.filter(it => it.faq_section?.id === section.id);
          return (
            <div className="my-2" id={section.id} key={section.id}>
              <CollapsibleCard
                className={styles.card}
                header={shown => (
                  <div className={styles.cardHeader}>
                    <h4>
                      {section.name}{' '}
                      <a
                        href={`#${section.id}?s=${normalizeString(section.name)}`}
                        onClick={() => {
                          navigator.clipboard.writeText(
                            `${props.location.href?.split('#')[0]}#${section.id}?s=${normalizeString(section.name)}`,
                          );
                        }}
                      >
                        <i className="icon icon-share icon-sm order-0 order-md-1" />
                      </a>
                    </h4>
                    <i
                      className={`icon ${shown ? 'icon-arrow-down' : 'icon-arrow-right'} icon-sm order-0 order-md-1`}
                    />
                  </div>
                )}
                key={uuid()}
                openedByDefault={isFiltered || props.location.href?.includes(`?s=${normalizeString(section.name)}`)}
              >
                <div className={styles.questions}>
                  {questions.map(item => (
                    <CollapsibleCard
                      className={styles.card}
                      header={shown => (
                        <div className={styles.cardHeader} id={item.id}>
                          <h5>
                            {item.question}{' '}
                            <a
                              href={`#${item.id}?s=${normalizeString(section.name)}&q=${normalizeString(
                                item.question,
                              )}`}
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  `${props.location.href?.split('#')[0]}#${item.id}?s=${normalizeString(
                                    section.name,
                                  )}&q=${normalizeString(item.question)}`,
                                );
                              }}
                            >
                              <i className="icon icon-share icon-sm order-0 order-md-1" />
                            </a>
                          </h5>
                          <i
                            className={`icon ${
                              shown ? 'icon-arrow-down' : 'icon-arrow-right'
                            } icon-sm order-0 order-md-1`}
                          />
                        </div>
                      )}
                      key={item.id}
                      openedByDefault={
                        isFiltered ||
                        props.location.href?.includes(
                          `?s=${normalizeString(section.name)}&q=${normalizeString(item.question)}`,
                        )
                      }
                    >
                      <div
                        className={styles.answer}
                        dangerouslySetInnerHTML={{
                          __html: item.answer.data.childMarkdownRemark.html,
                        }}
                      />
                    </CollapsibleCard>
                  ))}
                </div>
              </CollapsibleCard>
            </div>
          );
        })}
      </div>
    </div>
  );
}
